import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import LoadingComponent from './components/LoadingComponent'
import "./fonts/GothamBold.ttf"
ReactDOM.render(
<Suspense fallback={<LoadingComponent />}>
{/*   <React.StrictMode> */}
    <App />
{/*   </React.StrictMode> */}
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
